import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { useCookies } from 'react-cookie';
import ModalWindow from "../Components/ModalWindow";
import SignIn from "../Components/SignIn";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getCartCount, getCartList, getCartPrice, getOrderSettings, postCartDelete, postCartEmpty, postCartUpdate, productAvailability, userValidate, promotionOffer } from "../Services/serviceApi";
import ToastMessage from "../Components/toast-message";
import Availability from "../Components/availabilitycheck";
import { useParams } from "react-router-dom";
const Cart = ({ setData }) => {
  const id = 'cart';
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('')
  const [lgShow, setLgShow] = useState(false);
  const [signInDetails, setSignInDetails] = useState('')
  const [url, setURl] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO'], ['USERMOBILENUM']);
  const [modalData, setModal] = useState({ show: false, type: '', title: '', message: '' })
  const [openModal, setOpenModal] = useState({ show: false, type: '', title: '', message: '' })
  const navigate = useNavigate();
  const [cartId, setCartId] = useState([]);
  const [priceChanging, setPriceChanging] = useState([]);
  const [minimumAmount, setMinimumAmount] = useState('');
  const [maximumAmount, setMaximumAmount] = useState('');
  const [promotionPrice, setPromotionPrice] = useState('');
  const [isLoad, setIsLoad] = useState('Proceed to Checkout');
  const [userData, setUserData] = useState('');
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const usertype = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const userid = localStorage.getItem('userid')
  const userID = userid == null || userid == undefined ? userId : userid;
  const userType = userid == null || userid == undefined ? usertype : '';
  const userMobilenumber = (cookies.USERMOBILENUM != '' && cookies.USERMOBILENUM != undefined) ? cookies.USERMOBILENUM :'';
  // const promotionPrice = 150;
  useEffect(() => {
    getcartCounts()
    // getProducts()
    const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
    const signIn = (userid == null || userid == undefined ? signin : 'Sign out')
    setSignInDetails(signIn);
    
    // localStorage.removeItem('userid')
  }, [cartCount])

  useEffect(()=>{
    getCartItems(userID, userType)
    getPromotionOffer(userMobilenumber);
  },[])

  const getCartItems = (data, userType) => {
    getCartList(data, userType)
      .then(response => {
        setCartItems(response.data.cart_list || [])
      })
  }

  const getPromotionOffer = (userMobilenumber) => {
    promotionOffer(userMobilenumber)
      .then(response => {
        setPromotionPrice(response.message.amount && response.message.amount > 0 ? response.message.amount : '');
        // setPromotionPrice(response.message.amount);
        console.log('response promotionPrice',response.message.amount)
      })
  }
  const handleQuantityChange = (productId, action) => {

    let currentQuantity = cartItems.find(item => item.product_id === productId)?.quantity || 0;
    let updatedQuantity;
    if (action === 'increment') {
      updatedQuantity = currentQuantity + 1;
      setShow(true)
      setCartMsg('Success! Quantity added')
    } else if (action === 'decrement' && currentQuantity > 1) {
      updatedQuantity = currentQuantity - 1;
      setShow(true)
      setCartMsg('Quantity reduced successfully.')
    } else {
      return;
    }
    postCartUpdate(userID, productId, updatedQuantity, userType)
      .then(response => {
        setUserData(response)
      })
    const updatedCartItems = cartItems.map(item =>
      item.product_id === productId ? { ...item, quantity: updatedQuantity } : item
    );
    setCartItems(updatedCartItems);
  }
  const handleCartEmpty = (cartId) => {
    setCartId(cartId)
    if (cartId == undefined) {
      setModal({ show: true, type: 'confirmation', title: 'Empty Cart Confirmation', message: 'Are you sure want to Empty your Cart ?' });
    }
    else {
      setModal({ show: true, type: 'confirmation', title: 'Delete Product Confirmation', message: 'Are you sure want to delete the product ?' });
    }
  }

  /*  const getCartPriceUpdate =()=>{
     getCartPrice(userId).then(response =>{
     })
   } */


  const onCloseModel = (type) => {
    if (type == 1) {
      if (cartId == undefined) {
        postCartEmpty(userID, userType)
          .then(response => {
            getCartItems(userID, userType)
            getcartCounts()
            if (response.data.status == 1) {
              setShow(true)
              if (cartItems.length > 1) {
                setCartMsg('Products successfully removed from the cart.')
              } else {
                setCartMsg('Product successfully removed from the cart.')
              }
            }
          })
      }
      else if (cartId) {
        postCartDelete(cartId, userID, userType)
          .then(response => {
            // getCartItems(userID, userType)
            getcartCounts()
            if (response.data.status == 1) {
              const cartData = cartItems.filter(item=>item.id != cartId)
              setCartItems(cartData)
              setShow(true)
              setCartMsg('Product successfully removed from the cart.')
            }
          })
      } else {
        getCartItems(userID, userType)
      }
    }
  };

  const onClosePopup = (type) => {
    if (type === 1) {
      if (cartId.length > 0) {
        cartId.map((product) => {
          product.map((data) => {
            postCartDelete(data.cart_id, userID, userType)
              .then(response => {
                getCartItems(userID, userType)
                getcartCounts()
                checkProduct(userID)
              })
          })

        })
      }
      else if (cartId != 0) {
        postCartDelete(cartId, userID, userType)
          .then(response => {
            getCartItems(userID, userType)
            getcartCounts()
            checkProduct(userID)
          })
      }
      else if (priceChanging != '' && priceChanging.length > 0) {
        getCartPrice(userID).then(response => {
          getCartItems(userID, userType)
          getcartCounts()
          checkProduct(userID)
        })
      }
      else {
        getCartItems(userID, userType)
      }
    }
  }

  const getcartCounts = () => {
    getCartCount(userID, userType).then(response => {
      setCartCount(response)
    })
  }

  useEffect(() => {
    orderSettings();
    // checkProduct();
  }, []);

  const orderSettings = () => {
    getOrderSettings()
      .then(response => {
        setMinimumAmount(response[0].minimum_purchase)
        setMaximumAmount(response[0].maximum_purchase)
        localStorage.setItem('MaximumAmount', response[0].maximum_purchase)
      })
  }

  const setVariantModal = (trigger, userId) => {
    localStorage.setItem('userid', userId);
    if (trigger == 1) {
      checkProduct(userId);
      getCartItems(userId, '');
    }
  }

  const checkProduct = (userId) => {
    const maxAmount = localStorage.getItem('MaximumAmount')
    productAvailability(userId, '')
      .then(response => {
        setProductData(response)
        setIsLoad('Proceed to Checkout')

        let combinedMessage = [];
        let title = [];
        let cartid = [];
        setCartId(cartid)
        if (response.outOfStock.length > 0) {
          cartid.push(response.outOfStock)
          combinedMessage.push(response.outOfStock);
          title.push('This product is currently out of stock and cannot be added to your cart.')
        }
        if (response.variantChange.length > 0) {
          cartid.push(response.variantChange)
          combinedMessage.push(response.variantChange);
          title.push('This product is currently changed a variant and cannot be added to your cart.')
        }
        if (response.unitChange.length > 0) {
          cartid.push(response.unitChange)
          combinedMessage.push(response.unitChange);
          title.push('This product is currently changed a unit and cannot be added to your cart.')
        }
        if (response.inActiveProduct.length > 0) {
          cartid.push(response.inActiveProduct)
          combinedMessage.push(response.inActiveProduct);
          title.push('This product is currently unavailable and cannot be added to your cart.')
        }
        if (response.inActiveCatProduct.length > 0) {
          cartid.push(response.inActiveCatProduct)
          combinedMessage.push(response.inActiveCatProduct);
          title.push('This category is currently unavailable and cannot be added to your cart.')
        }
        if (combinedMessage.length !== 0) {
          setOpenModal({ show: true, type: 'success', title: title, message: combinedMessage });
          return;
        }
        else if (response.productPriceChange.length > 0) {
          let message = [];
          let title = [];
          setPriceChanging(response.productPriceChange);
          message.push(response.productPriceChange)
          title.push('This product is currently changed price and updated to your cart.')
          setOpenModal({ show: true, type: 'success', title: title, message: message });
          return;
        }
        else if (response.totalPrice < minimumAmount) {
          setModal({ show: true, type: 'success', title: `Minimum ₹${minimumAmount} Required for Checkout`, message: `please ensure your cart total exceeds ₹${minimumAmount} to proceed to checkout. Thank you!` });
          return;
        }
        else if (response.totalPrice > maxAmount) {
          setModal({ show: true, type: 'success', title: `Maximum amount exceeds`, message: `please ensure your cart total does not exceeds ₹${maxAmount} to proceed to checkout. Thank you!` });
          return;
        }
        // else {
        //   navigate('/checkout')
        // }

      })
  }

  const [productData, setProductData] = useState([])
  const isAuthenticated = (data) => {
    setIsLoad("Wait...")
    if (signInDetails == 'Sign In') {
      setLgShow(true);
      setURl(data)
    }
    productAvailability(userID, userType)
      .then(response => {
        setProductData(response)
        setIsLoad('Proceed to Checkout')
        if (signInDetails !== 'Sign In') {
          let combinedMessage = [];
          let title = [];
          let cartid = [];
          setCartId(cartid)
          if (response.outOfStock.length > 0) {
            cartid.push(response.outOfStock)
            combinedMessage.push(response.outOfStock);
            title.push('This product is currently out of stock and cannot be added to your cart.')
          }
          if (response.variantChange.length > 0) {
            cartid.push(response.variantChange)
            combinedMessage.push(response.variantChange);
            title.push('This product is currently changed a variant and cannot be added to your cart.')
          }
          if (response.unitChange.length > 0) {
            cartid.push(response.unitChange)
            combinedMessage.push(response.unitChange);
            title.push('This product is currently changed a unit and cannot be added to your cart.')
          }
          if (response.inActiveProduct.length > 0) {
            cartid.push(response.inActiveProduct)
            combinedMessage.push(response.inActiveProduct);
            title.push('This product is currently unavailable and cannot be added to your cart.')
          }
          if (response.inActiveCatProduct.length > 0) {
            cartid.push(response.inActiveCatProduct)
            combinedMessage.push(response.inActiveCatProduct);
            title.push('This category is currently unavailable and cannot be added to your cart.')
          }
          if (combinedMessage.length !== 0) {
            setOpenModal({ show: true, type: 'success', title: title, message: combinedMessage });
            return;
          }
          else if (response.productPriceChange.length > 0) {
            let message = [];
            let title = [];
            setPriceChanging(response.productPriceChange);
            message.push(response.productPriceChange)
            title.push('This product is currently changed price and updated to your cart.')
            setOpenModal({ show: true, type: 'success', title: title, message: message });
            return;
          }
          else if (response.totalPrice < minimumAmount) {
            setModal({ show: true, type: 'success', title: `Minimum ₹${minimumAmount} Required for Checkout`, message: `please ensure your cart total exceeds ₹${minimumAmount} to proceed to checkout. Thank you!` });
            return;
          }
          else if (response.totalPrice > maximumAmount) {
            setModal({ show: true, type: 'success', title: `Maximum amount exceeds`, message: `please ensure your cart total does not exceeds ₹${maximumAmount} to proceed to checkout. Thank you!` });
            return;
          }
          else {
            navigate(data)
          }
        }
      })
  };

  const calculateSubtotal = () => {
    if (!Array.isArray(cartItems) || cartItems.length === 0) {
      return 0;
    }
    return cartItems.reduce((acc, product) => {
      return acc + (parseInt(product.cart_price) !== 0 && product.cart_price != null ? product.cart_price * product.quantity : product.price * product.quantity);
    }, 0);
  };

  const calculateGrandTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = 0;
    if (promotionPrice && promotionPrice > 0) {
      return subtotal - promotionPrice;
    }
    return subtotal + tax;
  };

  return (
    <div>
      <Header cartCount={cartCount} userData={userData} signInDetails={signInDetails} page="cart" />
      <section className="cart-section mt-space pt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">My Cart</li>
            </ul>
          </div>
          <Row>
            {Array.isArray(cartItems) && cartItems.length > 0 ? <><Col lg={9} md={12} xs={12}>
              <div className="cart-title">
                <h4>My Cart</h4>
                {Array.isArray(cartItems) && cartItems.length > 0 && (
                  <Button variant="apply-btn" onClick={() => { handleCartEmpty() }}>
                    <span className="iconify">
                      <Icon icon="mdi:clear-circle-outline" />
                    </span>Clear Cart
                  </Button>)}
              </div>
              <div className="cart-body">
                <div className="table-responsive">
                  <Table className="table table-bordered cart-table">
                    {cartItems.map((product, index) => (
                      (product.stock_availability !== 0) && (
                        <tr key={index}>
                          <td width="5%">
                            <div className="cart-img">
                              <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                                <img
                                  src={product.product_image}
                                  alt={product.product_image}
                                />
                              </Link>
                            </div>
                          </td>
                          <td width="30%">
                            <div className="cart-price">
                              <Link to={`/product-detail/${product.product_slug}`} state={{ product }}>
                                <p>{product.product_name} / {product.product_name2} - {product.variant_name} {product.nic_name}</p>
                              </Link>
                            </div>
                          </td>
                          <td width="20%">
                            <div className="price">
                              <h5 className="text-content">Price</h5>
                              <h4 className="price-value">₹{(parseInt(product.cart_price) !== 0 && product.cart_price != null) ? product.cart_price : product.price}
                                {(parseInt(product.cart_price) !== 0 && product.cart_price != null) && <span className="price-low">₹{product.price}</span>}
                              </h4>
                              {(parseInt(product.cart_price) !== 0 && product.cart_price != null) && <p className="save-price"> You save ₹{product.price - product.cart_price}</p>}
                            </div>
                            {/* <div className="price">
                            <h5 className="text-content">Price</h5>
                            <h4>
                              <span className="price-value">₹{product.price}</span>
                              <><span className="price-low">₹81</span><br /> You save ₹ {product.actual_price - product.selling_price}</>
                            </h4>
                          </div> */}
                          </td>
                          <td width="15%">
                            <div className="cart-qty">
                              <h4 className="text-content">Qty</h4>
                              <div className="quantity-price">
                                <Button className="qty-button" onClick={() => handleQuantityChange(product.product_id, 'decrement')}>
                                  <span className="iconify">
                                    <Icon icon="ic:baseline-minus" />
                                  </span>
                                </Button>
                                <InputGroup className="qty-count">
                                  <Form.Control
                                    placeholder="2"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={product.quantity}
                                    onChange={(e) => handleQuantityChange(product.product_id, e.target.value)}

                                  />
                                </InputGroup>
                                <Button className="qty-button" onClick={() => handleQuantityChange(product.product_id, 'increment')}>
                                  <span className="iconify">
                                    <Icon icon="ic:baseline-plus" />
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="cart-total">
                              <p className="text-content">Total</p>
                              <h4 className="text-success">₹{parseInt(product.cart_price) !== 0 && product.cart_price !== null ? product.cart_price * product.quantity : product.price * product.quantity}</h4>
                            </div>
                          </td>
                          <td>
                            <div className="save-action">
                              <p className="text-content">Remove</p>
                              <Link onClick={() => handleCartEmpty(product.id)}>
                                <span className="iconify">
                                  <Icon icon="material-symbols:delete-outline" />
                                </span>
                              </Link>
                            </div>
                          </td>
                        </tr>)
                    ))}
                  </Table>
                </div>
              </div>
            </Col>
              <Col lg={3} md={12} xs={12}>
                <div className="cart-title">
                  <h4>Cart Summary</h4>
                </div>
                <div className="cart-left">
                  <div className="coupan-content">
                    <ul>
                      <li>
                        <h4 className="text-left text-bold">Subtotal</h4>
                        <h4 className="text-bold">₹{calculateSubtotal()}</h4>
                      </li>
                      {promotionPrice > 0 && (
                        <li>
                          <h4 className="text-left text-bold">Promotion offer</h4>
                          <h4 className="text-bold">- ₹{promotionPrice}</h4>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="summary-total">
                    <ul>
                      <li>
                        <h4>Grand Total</h4>
                        <h4 className="text-success">₹{calculateGrandTotal()}</h4>
                      </li>
                    </ul>
                  </div>
                  {Array.isArray(cartItems) ? (
                    <div className="cart-button">
                      <button onClick={() => isAuthenticated('/checkout')}>{isLoad}</button>
                    </div>
                  ) : (
                    <div className="cart-button">
                      <Link onClick={(event) => { event.preventDefault() }} to="/checkout">Proceed to Checkout</Link>
                    </div>
                  )}
                  <div className="return-button">
                    <Link to='/'>Continue Shopping</Link>
                  </div>
                </div>
              </Col></> : (<section className="pt-space pb-sapce">
                <div className="empty-cart text-center para-text">
                  <img
                    className="zoom-effect"
                    src="../assets/images/empty-cart.svg"
                    alt=""
                  />
                  <p>Cart is Empty</p>
                </div>
              </section>)}
          </Row>
        </Container>
      </section>
      <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
      <ModalWindow data={modalData} onCloseModel={onCloseModel} />
      <Availability data={openModal} onClosePopup={onClosePopup} />
      <SignIn lgShow={lgShow} setLgShow={setLgShow} signInDetails={signInDetails} setSignInDetails={setSignInDetails} url={url} productData={productData} setVariantModal={setVariantModal} />
      <Footer />
    </div>
  );
};

export default Cart;

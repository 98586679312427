import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { getTrackOrder } from "../Services/serviceApi";
import { useLocation } from "react-router-dom";
import { format } from 'date-fns';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

const TrackOrders = () => {
  const [formErrors, formError] = useState({});
  const [resultMsg, setResultMsg] = useState({});
  const [orderData, setOrderData] = useState('');
  const [orderId, setOrderId] = useState([]);
  const [date, setDate] = useState('');
  const location = useLocation()
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('order_id');
    if (id) {
      const decodedId = atob(id);
      setOrderId(decodedId);
      trackOrder(decodedId);
    }
  }, [location.search])


  const trackOrder = (id) => {
    setOrderData('')
    const orderToTrack = id || orderId;
    const error = {};
    setResultMsg({});
    if (!orderId) {
      error.orderId = 'Enter Order Id';
    }
    if (Object.keys(error).length == 0) {
      getTrackOrder(userId, orderToTrack).then((res) => {
        // setResultMsg(res)
        if(res.message){
          error.orderId = res.message
        }
        const content = Object.keys(res)
          .filter(key => !isNaN(key))
          .map(key => {
            const data = res[key];
            // const dateTime = new Date(data.created_date.replace(/-/g, '/'));
            const [datePart, timePart] = data.created_date.split(' ');
            const [day, month, year] = datePart.split('-');
            const formattedDateStr = `${year}/${month}/${day} ${timePart}`;
            const dateTime = new Date(formattedDateStr);
            const formatDate = format(dateTime, "EEEE, MMMM do yyyy");
            const formatTime = format(dateTime, "hh:mm a");
            return {
              date: formatDate,
              time: formatTime,
              detail: data
            };
          });

        setOrderData(content);
        setDate(res[0].created_date)
      })
    }
    formError(error)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/track-orders?order_id=${btoa(orderId)}`)
    // navigate(`/track-orders?order_id=${orderId}`);
    trackOrder();
  };

  const onChange = (e) => {
    setOrderId(e.target.value);

  };

  return (
    <div>
      <Header />
      <section className="my-order mt-space section-pad">
        <Container>
          <Row>
            <Col lg={3} md={4} xs={12}>
              <ProfileSidemenu />
            </Col>
            <Col lg={9} md={8} xs={12}>
              <div className="track-top">
                <h4>Track Order</h4>
              </div>

              <div className="track-orders">
                <Row className="d-flex align-items-center justify-content-center">
                  <Col lg={6} md={6} xs={12}>
                    <form onSubmit={handleSubmit}>
                      <div className="track-search search-icon d-flex align-items-center">
                        <InputGroup className="search-bar">
                          <Form.Control
                            placeholder="Enter Order ID"
                            aria-label="Order ID"
                            aria-describedby="basic-addon1"
                            type="text"
                            name="orderId"
                            value={orderId}
                            onChange={onChange}
                          />


                        </InputGroup>
                        <span className="add-button">
                          <Button
                            variant="primary"
                            to="/"
                            className="btn-lg btn-submit ms-3 btn-size"
                            type="submit"
                          >
                            Track
                          </Button>
                        </span>
                      </div>
                    </form>
                    {formErrors.orderId != '' && formErrors.orderId != undefined && (<Form.Text className="error text-danger errortext">{formErrors.orderId}</Form.Text>)}
                  </Col>

                  <Col lg={2} md={3} xs={12}>
                    <div>
                      {/* <Button variant="primary" to="/" className="btn-lg btn-cancel" type="submit">Cancel</Button> */}
                    </div>
                  </Col>

                  <div className="order-track">
                    <div className={orderData.length>0 ? "time-line" : ""}  >
                      <ul>
                        {orderData && orderData.map((data, index) => (
                          <li className="active">
                            <div className="left-side">
                              <p className="text-bold">
                                {data.date},
                              </p>
                              <p className="text-bold">{data.time}</p>
                            </div>
                            <div className="content">
                              <p className="text-bold">{data.detail.delivery_status != "Order Delivered" && data.detail.delivery_status != "Order Return" ? 'Order' : '' } {data.detail.delivery_status}</p>
                              <p className="text-grey">{data.detail.delivery_status == 'Pending' ? "Your Order Pending." : ""}</p>
                              <p className="text-grey">{data.detail.delivery_status == 'Confirm' ? "Your Order Confirmed." : ""}</p>
                              <p className="text-grey">{data.detail.delivery_status == 'Out for Delivery' ? "Your product is Out for Delivery." : ""}</p>
                              <p className="text-grey">{data.detail.delivery_status == 'Order Delivered' ? "Your product is Delivered" : ""}</p>
                              <p className="text-grey">{data.detail.delivery_status == 'Order Return' ? "Your product is Returned" : ""}</p>

                            </div>

                          </li>
                        ))}
                        {/* <li className="active">
                          <div className="left-side">
                            <p className="text-bold">
                              Friday, February28th 2023,
                            </p>
                            <p className="text-bold">05:01 PM</p>
                          </div>
                          <div className="content">
                            <p className="text-bold">Order Confirmed</p>
                            <p>Your Order Confirmed.</p>
                          </div>
                        </li> */}
                        {/* <li>
                          <div className="left-side">
                            <p className="text-bold">Friday, March05th 2023,</p>
                            <p className="text-bold">05:01 PM</p>
                          </div>
                          <div className="content">
                            <p className="text-bold">Ready to Despatch</p>
                            <p>Your product is Ready to Despatch.</p>
                          </div>
                        </li> */}
                        {/* <li>
                          <div className="left-side">
                            <p className="text-bold">Friday, March20th 2023,</p>
                            <p className="text-bold">05:01 PM</p>
                          </div>
                          <div className="content">
                            <p className="text-bold">Out for Delivery</p>
                            <p>Your product is Out for Delivery.</p>
                          </div>
                        </li> */}
                        {/* <li>
                          <div className="left-side">
                            <p className="text-bold">
                              Tuesday, March 25th 2023,
                            </p>
                            <p className="text-bold">04:00 pm</p>
                          </div>
                          <div className="content">
                            <p className="text-bold">Delivered</p>
                            <p>Your product is delivered.</p>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default TrackOrders;

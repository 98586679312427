import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import InputGroup from "react-bootstrap/InputGroup";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactImageMagnify from 'react-image-magnify';
import { getCartCount, getCartInsert, getProductDetails, postCartUpdate, postFavouriteRemove, postFavourites, relatedProducts, postSharerData } from "../Services/serviceApi";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { FacebookIcon, WhatsappIcon, XIcon } from 'react-share';
import { useParams } from 'react-router-dom';
import ToastMessage from "../Components/toast-message";
import SignIn from "../Components/SignIn";
import SkeletonLoader from "../Components/skeleton-loader";

const ProductDetail = ({ callFn }) => {
  const { state } = useLocation();
  const { id } = useParams();
  const data = (state != null) ? state.product.product_slug : '';
  const productId = (id != '' && id != undefined) ? id : data;
  const [product, setProduct] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [shareClick, setShareClick] = useState(false);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const [productImages, setProductImages] = useState([]);
  const [img, setImg] = useState('');
  const [keyWordsList, setKeyWordsList] = useState([]);
  const [show, setShow] = useState(false);
  const [cartMsg, setCartMsg] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [relatedLoading, setRelatedLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [signInDetails, setSignInDetails] = useState('');
  const [url, setURl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [previousStateData, setPreviousStateData] = useState('');
  const [userData, setUserData] = useState('');

  const paragraphStyle = {
    WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', display: '-Webkit-box',
  }

  const carouselOptions = {
    loop: false,
    margin: 20,
    responsive: {
      0: {
        items: 2, // Two item on mobile
      },
      1024: {
        items: 4, // Three item on desktop
      },
      767: {
        items: 3, // Four items on tab
      },
    },
  }

  useEffect(() => {
    const signin = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? 'Sign out' : 'Sign In';
    setSignInDetails(signin);
  }, [])

  useEffect(() => {
    getProduct()
    updateQuantityChange()
  }, [productId])

  const getProduct = () => {
    setPreviousStateData(productId)
    setIsLoading(true)
    setTimeout(() => {
      getProductDetails(productId, userId, userType).then(response => {
        //getRelatedProductData(response.products[0].product_slug)
        setProduct(response.products)
        setImg(response.products.product_image)
        setKeyWordsList(response.products.keywords.split(','))
        setProductImages((response.prod_image.length > 0) ? { myArr: [{ product_id: response.prod_image[0].product_id, image: response.products.product_image }, ...response.prod_image] } : [])
        setIsLoading(false)
        if (parseInt(response.products.fav_prod) === 1) {
          setIsInFavorites(true)
        }
      })
    }, 500);
  };

  const getRelatedProductData = (data) => {
    relatedProducts(data, userId, userType).then(response => {
      setRelatedProduct(response.data)
      setRelatedLoading(false)
    })
    return [relatedProduct];
  }

  const getCartCounts = () => {
    getCartCount(userId, userType).then(response => {
      setCartCount(response)
    })
  }

  if (previousStateData != productId) {
    getRelatedProductData(productId)
  }

  const addToFavorites = (userId, productId, data) => {
    if (signInDetails === 'Sign In') {
      setLgShow(true);
      setURl(data)
    }
    else {
      if (!isInFavorites) {
        postFavourites(userId, productId)
          .then(response => {
            setIsInFavorites(true);
            setUserData(response)
          })
      }
      else {
        setIsInFavorites(handleFavoriteDelete(productId))
      }
    }
  };

  const handleFavoriteDelete = (productId) => {
    postFavouriteRemove(userId, productId)
      .then(response => {
        setUserData(response)
      })
  }

  const updateRelatedProduct = (id) => {
    setIsInFavorites(false)
    setIsLoading(true)
    getProductDetails(id, userId, userType).then(response => {
      getRelatedProductData(response.products[0].product_slug)
      setProduct(response.products)
      setProductImages((response.prod_image.length > 0) ? { myArr: [{ product_id: response.prod_image[0].product_id, image: response.products[0].product_image }, ...response.prod_image] } : [])
      setImg(response.products[0].product_image)
      setKeyWordsList(response.products[0].keywords.split(','))
      setIsLoading(false)
    })
  };

  const addToCart = (productId) => {
    getCartInsert(userType, userId, productId).then(response => {
      if (response.data.status === 1) {
        setCartMsg(response.data.message)
        setShow(true)
        updateQuantityChange(productId, '');
        setUserData(response)
      }
    })
  };

  const updateQuantityChange = (productId, action) => {
    if (product.product_id === productId) {
      let currentQuantity = parseInt(product.cart_qty) || 0;
      let updatedQuantity;
      if (action === 'increment') {
        updatedQuantity = currentQuantity + 1;
        setShow(true);
        setCartMsg('Success! Quantity added to your cart. Keep on shopping!');
      } else if (action === 'decrement' && currentQuantity > 0) {
        updatedQuantity = currentQuantity - 1;
        if (updatedQuantity === 0) {
          setShow(true);
          setCartMsg('Product successfully removed from the cart.');
        } else {
          setShow(true);
          setCartMsg('Quantity reduced successfully. Your cart is updated.');
        }
      } else if (action === '') {
        updatedQuantity = 1;
        setShow(true);
      } else {
        return; 
      }
      const updatedProduct = {
        ...product,
        cart_qty: updatedQuantity
      };
      postCartUpdate(userId, productId, updatedQuantity, userType)
        .then(response => {
          setUserData(response);
          setCartCount(response);
        });
        setProduct(updatedProduct);
    }
  };
  

  const addRelatedProductToCart = (productId) => {
    getCartInsert(userType, userId, productId).then(response => {
      console.log('Related product',response);
      setCartMsg(response.data.message)
      setShow(true)
      setCartCount(response)
      setUserData(response)
      relatedProductUpdateQuantityChange(productId, '');
    })
  };

  const relatedProductUpdateQuantityChange = (productId, action) => {
    const updatedproducts = relatedProduct.map(product => {
      if (product.id === productId) {
        let currentQuantity = parseInt(product.cart_qty) || 0;
        let updatedQuantity;
        if (action === 'increment') {
          updatedQuantity = currentQuantity + 1;
          setShow(true);
          setCartMsg('Success! Quantity added to your cart. Keep on shopping!');
        } else if (action === 'decrement' && currentQuantity > 0) {
          updatedQuantity = currentQuantity - 1;
          if (updatedQuantity === 0) {
            setShow(true);
            setCartMsg('Product successfully removed from the cart.');
          } else {
            setShow(true);
            setCartMsg('Quantity reduced successfully. Your cart is updated.');
          }
        } 
        else if (action === '') {
          updatedQuantity = 1;
          setShow(true);
        } 
        else {
          return product;
        }
        postCartUpdate(userId, productId, updatedQuantity, userType)
          .then(response => {
            setUserData(response)
          })
        return {
          ...product,
          cart_qty: updatedQuantity
        };
      }
      return product;
    });
    setRelatedProduct(updatedproducts);
  };

  const socialLogin = (type, video) => {
    setImageUrl(video.product_image)
    postSharerData(video.product_slug)
      .then(response => {
        if (type === '1') {
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${response}`);
        }
        if (type === '2') {
          window.open(`https://wa.me/?text=${response}`);
        }
        if (type === '3') {
          window.open(`https://twitter.com/intent/tweet?url=${response}`);
        }
      })
  }

  const hoverHandler = (images, i) => {
    setImg(images);
    refs.current[i].classList.add('active');
    for (var j = 0; j < images.length; j++) {
      if (i !== j) {
        refs.current[j].classList.remove('active');
      }
    }
  };

  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const getRelatedProducts = () => {
    const content = [];
    relatedProduct.map((product) => {
      content.push(<Col lg={12} md={12} xs={12}>
        <div className="product-box detail-box">
          <Link to={`/product-detail/${product.product_slug}`} state={{ product }} onClick={() => { updateRelatedProduct(product.product_slug) }} >
            <div className="product-img">
              <img
                className="zoom-effect"
                src={product.product_image}
                alt=""
              />
              <div className="product-icon">
                {product.today_special === "1" ? (
                  <img src="../assets/images/special-icon.svg" alt="" />
                ) : ('')}
              </div>
              {parseInt(product.offer_price) !== 0 && product.offer_price != null ? (
                <div className="product-offer-box offer-orange">
                  <span>
                    <strong>{Math.round((product.price - product.offer_price) / product.price * 100)}%</strong> <br />
                    <span className="off-text">OFF</span>
                  </span>
                </div>
              ) : ('')}
            </div>
            <div className="product-title">
              <h4>{product.product_name} / {product.product_name2} - {product.variant_name + ' ' + product.nic_name}</h4>
            </div>
          </Link>
          <div className="select-card">
            <Row className="d-flex align-items-center">
              <Col lg={6} md={12} xs={12}>
                <div className="price-list">
                  <h5>
                    <span className="price-tag">
                      ₹{(parseInt(product.offer_price) !== 0 && product.offer_price != null) ? product.offer_price : product.price}
                    </span>
                    {(parseInt(product.offer_price) !== 0 && product.offer_price != null) && <>
                      <span className="price-low">
                        ₹{product.price}
                      </span>
                      <br /> You save ₹{product.price - product.offer_price}</>}
                  </h5>
                </div>
              </Col>
              <Col lg={6} md={12} xs={12}>
                {product.cart_qty === null || product.cart_qty === 0 ? (
                  <div className="cart-btn btn-add btn-full">
                    {product.stock_availability === '0' ? (
                      <p className="out_of_stock">Out of Stock</p>) : (

                      <Link to="javascript:;" onClick={() => addRelatedProductToCart(product.id)}>
                        <span className="iconify">
                          <Icon icon="solar:cart-4-linear" />
                        </span>
                        <span className="cart-hide">Add to Cart</span>
                      </Link>
                    )}
                  </div>) :
                  (
                    <div className="price-add">
                      {((product.stock_availability == '0') ?
                        <p className="out_of_stock">Out of Stock</p> :
                        (product.cart_qty !== null || product.cart_qty !== 0) ? (
                          <div className="quantity-price">
                            <Button className="qty-button" onClick={() => relatedProductUpdateQuantityChange(product.id, 'decrement')}>
                              <span className="iconify">
                                <Icon icon="ic:baseline-minus" />
                              </span>
                            </Button>
                            <InputGroup className="qty-count">
                              <Form.Control
                                placeholder="1"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={product.cart_qty}
                                onChange={(e) => relatedProductUpdateQuantityChange(product.id, e.target.value)}
                              />
                            </InputGroup>
                            <Button className="qty-button" onClick={() => relatedProductUpdateQuantityChange(product.id, 'increment')}>
                              <span className="iconify">
                                <Icon icon="ic:baseline-plus" />
                              </span>
                            </Button>
                          </div>
                        ) : ('')
                      )}
                    </div>

                  )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>)
    })
    return content;
  }

  return (
    <div>
      <Header cartCount={cartCount} signInDetails={signInDetails} userData={userData} />
      <section className="product-detail mt-space pt-space">
        {/* no record */}
        {/* <section className="">
            <div className="no-record text-center para-text">
              <img
                className="zoom-effect"
                src="../assets/images/no-record.svg"
                alt="Cart Image" title="Empty Cart"
              />
              <p>No Record</p>
            </div>
          </section> */}
        {/* no record */}

        <Container>
          {(isLoading) ? <Row>
            <Col lg={5} md={5} xs={12}>
              <div className="left-load">
                <div className="skeleton-boxone">
                  <div className=" left-1 skeleton-loader"></div>
                  <div className="left-2 skeleton-loader"></div>
                </div>
                <div className="skeleton-boxtow">
                  <div className="product-content skeleton-loader"></div>
                  <div className="product-price skeleton-loader"></div>
                  <div className="product-add skeleton-loader"></div>
                  <div className="product-share skeleton-loader"></div>
                  <div className="product-desription skeleton-loader"></div>
                  <div className="product-tags skeleton-loader"></div>
                  <div className="product-delivery skeleton-loader"></div>
                </div>
              </div>
            </Col>
          </Row> : <><div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li>
                {(product) && <Link to={`/category/${product.category_slug}`} state={product.category_slug} >
                  {product.category_name}
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>}
              </li>
              {(product) && <li className="active">{product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)}/{product.product_name2}</li>}
            </ul>
          </div>
            <Row>
              <Col lg={5} md={5} xs={12}>
                <div className="product-dt-view">
                  <div className="left">
                    <div className="left-size">
                      <div className="left-1">
                      </div>
                      {(productImages.myArr != undefined) ? productImages.myArr.map((images, i) => (
                        <div className={i === 0 ? 'img-wrap active' : 'img-wrap'} key={i}
                          onMouseOver={() => hoverHandler(images.image, i)}
                          ref={addRefs}
                        >
                          <img src={(images) ? images.image : ''} alt="" />
                        </div>
                      )) : <div className={'img-wrap active'}
                        onMouseOver={() => hoverHandler(product.product_image)}
                        ref={addRefs}
                      >
                        <img src={(product.length > 0) ? product.product_image : product.product_image} alt="" />
                      </div>
                      }

                    </div>

                    <div className="left-2">
                      <div className="product-icon product-icon-detail">
                        {product.today_special === "1" ? (
                          <img src="../assets/images/special-icon.svg" alt="" />
                        ) : ('')}
                      </div>
                      {parseInt(product.selling_price) !== 0 && product.selling_price != null ? (
                        <div className="product-offer-box offer-orange">

                          <span>
                            <strong>{Math.round((product.actual_price - product.selling_price) / product.actual_price * 100)}%</strong> <br />
                            <span className="off-text">OFF</span>
                          </span>
                        </div>
                      ) : ('')}
                      <ReactImageMagnify {...{
                        smallImage: {
                          alt: 'ProductImage',
                          isFluidWidth: true,
                          src: img,
                        },
                        largeImage: {
                          src: img,
                          width: 1400,
                          height: 1200,
                        },
                        isHintEnabled: true,
                        enlargedImagePosition: 'over',
                        // lensStyle: {backgroundColor:'rgba(0,0,0,0.9)'}
                      }} />
                    </div>
                  </div>
                </div>
              </Col>
                <Col lg={7} md={7} xs={12}>
                  {/* <div className="product-dt-right skeleton-loader"></div> */}
                  <div className="product-dt-right">
                    <h4>{product.product_name.charAt(0).toUpperCase() + product.product_name.slice(1)} / {product.product_name2} - {product.variant_name}{product.nic_name}</h4>
                    <div className="price-list">
                      <h5>
                        {<span className="price-tag">₹{(parseInt(product.selling_price) !== 0 && product.selling_price != null) ? product.selling_price : product.actual_price}</span>}
                        {(parseInt(product.selling_price) !== 0 && product.selling_price != null) && <><span className="price-low">₹{product.actual_price}</span> <br /> You
                          save ₹ {product.actual_price - product.selling_price}</>}
                      </h5>
                    </div>

                    <div className="select-card">
                      <Row className="d-flex align-items-center">
                        <Col lg={3} md={6} xs={6}>
                          {product.cart_qty === null || product.cart_qty === 0 ? (

                            <div className="cart-btn btn-add  btn-left">
                              {product.stock_availability == 0 ? (<p className="out_of_stock stock-text">Out of Stock</p>) : (
                                <Link to="javascript:;" onClick={() => addToCart(product.product_id)}>
                                  <span className="iconify">
                                    <Icon icon="solar:cart-4-linear" />
                                  </span>
                                  <span className="cart-hide">Add to Cart</span>
                                </Link>
                              )}
                            </div>) : (
                            <div
                              className="price-add detail-add"
                            > {((product.stock_availability == 0) ?
                              <p className="out_of_stock stock-text">Out of Stock</p> :
                              (product.cart_qty !== null || product.cart_qty !== 0) ? (

                                <div className="quantity-price">
                                  <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'decrement')}>
                                    <span className="iconify">
                                      <Icon icon="ic:baseline-minus" />
                                    </span>
                                  </Button>
                                  <InputGroup className="qty-count">
                                    <Form.Control
                                      placeholder="1"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      // value={productQuantities[product.product_id] || ''}
                                      value={product.cart_qty}
                                      onChange={(e) => updateQuantityChange(product.product_id, e.target.value)}
                                    />
                                  </InputGroup>
                                  <Button className="qty-button" onClick={() => updateQuantityChange(product.product_id, 'increment')}>
                                    <span className="iconify">
                                      <Icon icon="ic:baseline-plus" />
                                    </span>
                                  </Button>
                                </div>
                              ) : ('')
                            )}
                            </div>
                          )}

                        </Col>
                      </Row>
                    </div>
                    <div className="buy-box">
                      <Link onClick={() => addToFavorites(userId, product.product_id, 'productdetail')}
                      >
                        <span>
                          <span className="iconify">
                            {isInFavorites ? <Icon icon="mdi:heart"
                              style={{ color: 'red' }} /> : <Icon icon="mdi:heart-outline"
                                style={{ color: 'black' }} />}
                          </span>
                          {isInFavorites ? 'Added to Favourite' : 'Add to Favourite'}
                          {/* Add to Favourite */}
                        </span>
                      </Link>

                      {/* <Link> */}
                      <span className="share-box">
                        <span className="iconify click-effect" onClick={() => { setShareClick((!shareClick) ? true : false) }}>
                          <Icon icon="uil:share" />
                          <span className="share-text" >Share</span>
                        </span>
                        {(shareClick) && <div className="share-link">
                          <span className="iconify-share" onClick={() => { socialLogin('1', product) }} >
                            <FacebookIcon size={20} round={true} />
                          </span>
                          <span className="iconify-share" onClick={() => { socialLogin('2', product) }} >
                            <WhatsappIcon size={20} round={true} />
                          </span>
                          <span className="iconify-share" onClick={() => { socialLogin('3', product) }} >
                            <XIcon size={20} round={true} />
                          </span>
                          <span className="iconify-share" onClick={() => { setShareClick((!shareClick) ? true : false) }}>
                            Close
                          </span>
                        </div>}
                      </span>
                      {/* </Link> */}

                    </div>

                    <div className="detail-info">
                      <h4>Description</h4>
                      <p style={isOpen ? null : paragraphStyle} dangerouslySetInnerHTML={{__html: product.product_description}}>
                        {/* {product.product_description} */}
                      </p>
                      {product.product_description > product.product_description.substring(0, 350) ? (
                        <Link className="readmore" onClick={() => setIsOpen(!isOpen)} >{isOpen ? 'Read Less' : 'Read More'}</Link>) :
                        ('')}
                    </div>
                    {(keyWordsList != '' && keyWordsList.length > 0) && <div className="tags">
                      <ul>
                        <li>
                          <span className="iconify">
                            <Icon icon="ant-design:tags-outlined" />
                          </span>
                          Tags:
                        </li>
                        {keyWordsList.map((keys, index) => (
                          <li key={index} >
                            <Link>{keys}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>}
                    {/* <div className="delivery-value">
                     <p> <span className="text-success fw-bold">Free Delivery</span> minimum value ₹300</p>
                   </div> */}
                    <div className="free-delivery mt-4">
                      <div className="rows">
                        <div className="icon">
                          <img
                            src="../assets/images/Delivery.gif"
                            alt=""
                            title=""
                          />
                        </div>
                        <div className="content">
                          <p>
                            <strong>Enjoy free delivery</strong> on every order.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              
            </Row></>}
        </Container>
      </section>

      <section className="related-product pt-space">
        {(relatedLoading) ? <SkeletonLoader /> : (relatedProduct.length > 0) && <Container>
          <div className="product-heading text-start">
            <h3 className="text-start text-product">Related Products</h3>
          </div>
          <OwlCarousel
            items={4}
            className="owl-theme"
            {...carouselOptions}
            margin={25}
            autoplay={true}
          >{getRelatedProducts()}
          </OwlCarousel>
        </Container>}
      </section>
      <ToastMessage show={show} setShow={setShow} cartMsg={cartMsg} />
      <SignIn lgShow={lgShow} setLgShow={setLgShow} signInDetails={signInDetails} setSignInDetails={setSignInDetails} url={url} />
      <Footer />
    </div>
  );
};

export default ProductDetail;

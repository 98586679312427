import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Icon } from "@iconify/react";
/* import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion"; */

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const WhyUs = () => {

  const [pageContent,setPageContent] = useState([]);

  useEffect(()=>{
    const content = localStorage.getItem('cmsData');
    const content1 = JSON.parse(content)
    if(content1 !=null){
      setPageContent(content1)
    }
  },[])

  /* const getWhyUsContent = () =>{
    const content = [];
    if(pageContent !=null && pageContent.length > 0){
      pageContent.map((data)=>{
        content.push(
          <Col lg={3} md={4} xs={6}>
            <div className="whychoose-box">
              <Link>
                <div className="choose-img">
                  <img
                    data-aos="flip-right"
                    src="../assets/images/save-more 1.svg"
                    alt=""
                  />
                </div>
                <h4>Buy More. Save More.</h4>
                <p>
                  Lorem ipsum is placeholder text commonly used in the
                  graphic, print, and publishing industries for previewing
                  layouts and visual mockups.
                </p>
              </Link>
            </div>
          </Col>
        )
      })
      return content;
    }
  } */

  return (
    <div>
      <Header />
      <section className="howit-works  mt-space  pt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to ='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              {(pageContent !=null && pageContent.length > 0) && <li className="active">{pageContent[0].page_name}</li>}
            </ul>
          </div>

          <div className="product-heading">
          {(pageContent !=null && pageContent.length > 0) && <h3>
             {pageContent[0].title}
             <h3>Why choté kisan</h3>
            </h3>}
            
          </div>
          <div className="why-list">
            <Container>
            {(pageContent !=null && pageContent.length > 0) && <div dangerouslySetInnerHTML={{ __html: pageContent[0].description}} onClick={(e)=>{e.preventDefault()}}/>}
              {/* {getWhyUsContent()} */}
              <Row>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/save-more 1.svg"
                      alt=""
                    />
                  </div>
                  <h4>Unbeatable Prices</h4>
                  <p>
                  We work with small farmers to give you a better price. We eliminate wastages by having a robust supply chain helping us to give you unbeatable prices year around.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/clip-path.svg"
                      alt=""
                    />
                  </div>
                  <h4>100% Fresh Produce</h4>
                  <p>
                  Our farmers supply us on a daily basis. We do not store the fruits and vegetables as it's delivered same day. This helps us get you the freshest of produce on every order.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/delivery1.svg"
                      alt=""
                    />
                  </div>
                  <h4>Free Delivery</h4>
                  <p>
                  Chote Kisan prides itself on excellent customer service and prompt timelines of deliverables. We take your time seriously and make sure it is on time every time.
                  </p>
                </div>
              </Col>
              <Col lg={3} md={4} xs={6}>
                <div className="whychoose-box">
                  <div className="choose-img">
                    <img
                      data-aos="flip-right"
                      src="../assets/images/logo-icon.svg"
                      alt=""
                    />
                  </div>
                  <h4>Support Farmers</h4>
                  <p>
                  Small farmers form the backbone of our country. They feed our nation working hard on the fields year in and year out. We have making a huge difference to their lifestyles by connecting them to consumers like you so they get a better price for the work they put in. Support our local farmers. They need your help.
                  </p>
                </div>
              </Col>
            </Row>
              {/* <Row>
                <Col lg={3} md={4} xs={6}>
                  <div className="whychoose-box">
                    <Link>
                      <div className="choose-img">
                        <img
                          data-aos="flip-right"
                          src="../assets/images/save-more 1.svg"
                          alt=""
                        />
                      </div>
                      <h4>Buy More. Save More.</h4>
                      <p>
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </Link>
                  </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                  <div className="whychoose-box">
                    <Link>
                      <div className="choose-img">
                        <img
                          data-aos="flip-right"
                          src="../assets/images/clip-path.svg"
                          alt=""
                        />
                      </div>
                      <h4>100% Fresh Products</h4>
                      <p>
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </Link>
                  </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                  <div className="whychoose-box">
                    <Link>
                      <div className="choose-img">
                        <img
                          data-aos="flip-right"
                          src="../assets/images/delivery1.svg"
                          alt=""
                        />
                      </div>
                      <h4>Free Delivery</h4>
                      <p>
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </Link>
                  </div>
                </Col>
                <Col lg={3} md={4} xs={6}>
                  <div className="whychoose-box">
                    <Link>
                      <div className="choose-img">
                        <img
                          data-aos="flip-right"
                          src="../assets/images/logo-icon.svg"
                          alt=""
                        />
                      </div>
                      <h4>Support Farmers</h4>
                      <p>
                        Lorem ipsum is placeholder text commonly used in the
                        graphic, print, and publishing industries for previewing
                        layouts and visual mockups.
                      </p>
                    </Link>
                  </div>
                </Col>
              </Row> */}
            </Container>
          </div>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default WhyUs;

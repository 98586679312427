import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { useCookies } from "react-cookie";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ProfileSidemenu from "../Components/ProfileSidemenu";
import { getOrderList } from "../Services/serviceApi";

const MyOrders = () => {
  const [orders, setorders] = useState([] || '');
  const [isLoading, setIsLoading] = useState(true);
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const [message, setMessage] = useState('');

  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;

  const orderListing = () => {
    getOrderList(userId)
      .then(response => {
        setIsLoading(false)
        if (response.data.orderlist) {
          const ordersList = response.data.orderlist.map((order, index) => {
            if (order && order.length > 0 && order[0].cart_order_id) {
              return {
                cart_order_id: order[0].cart_order_id,
                products: order,
                order_date: order[0].order_date,
                order_status: response.data.delivery_status[index],
                delivery_date: response.data.delivery_date[index]
              };
            } else {
              return null;
            }
          }).filter(order => order !== null);
          setorders(ordersList);
        }
        if (response.data.message) {
          setMessage(response.data.message)
        }
      })
  }

  useEffect(() => {
    orderListing()
  }, [])

  return (
    <div>
      <Header />

      <section className="cart-box mt-space pb-sapce pt-space">
        <div className="empty-cart text-center para-text">
          <img
            className="zoom-effect"
            src="../assets/images/empty-cart.svg"
            alt=""
          />
          <p>Your Cart is Empty</p>
        </div>
      </section>

      <section className="my-order mt-space section-pad">
        <Container>
          <Row>
            <Col lg={2} md={3} xs={12}>
              <ProfileSidemenu />
            </Col>

            <Col lg={9} md={9} xs={12}>
              <div className="order-top">
                <h4>My Orders</h4>
              </div>
              {(!isLoading) ? orders.length > 0 ? (orders.map((order) => (<><div key={order.cart_order_id} className="mycard-box">
                <div className="card">
                  <div className="order-head">
                    <Row className="d-flex align-items-center">

                      <Col lg={6} md={6} xs={7}>
                        <div className="track-id">
                          {/* <Link to={`/order-details?order_id=${order.cart_order_id}`}> */}
                          <Link to={`/order-details?order_id=${btoa(order.cart_order_id)}`}>
                            Order ID: <strong>{order.cart_order_id}</strong>
                          </Link>
                        </div>
                      </Col>
                      <Col lg={6} md={6} xs={5}>
                        <div className="track-cart">
                          {/* <Link to={`/track-orders?order_id=${order.cart_order_id}`}> */}
                          <Link to={`/track-orders?order_id=${btoa(order.cart_order_id)}`}>
                            <span className="iconify">
                              <Icon icon="carbon:location" />
                            </span>
                            Track Order
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="order-body">
                    <Row className="d-flex align-items-center">
                      <Col lg={7} md={12} xs={12}>
                        {order.products.map((product, index) => (
                          <div className="product-row" key={index}>
                            <Row className="d-flex align-items-center">
                              <Col lg={2} md={3} xs={3}>
                                <div className="my-order-img">
                                  <img
                                    src={product.product_image}
                                    alt=""
                                    title=""
                                  />
                                </div>
                              </Col>
                              <Col lg={10} md={9} xs={8}>
                                <div className="delivery-product">
                                  <h4>
                                    {product.product_name} - {product.product_name2} {product.variant_name}{product.nic_name}
                                  </h4>
                                  <p>{product.quantity} x ₹{product.price}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Col>
                      <Col lg={5} md={12} xs={12}>
                        <div className="delivery-detail">
                          {order.order_status != "Order Return" ?
                            <p>
                              <strong>
                                {/* Delivered on Tuesday,April 22th 2024 */}
                                {/* {order.order_status =="Order Delivered" ? 'Delivered on ' : 'Will Delivery on '} */}
                                {order.delivery_date}
                              </strong>
                            </p>
                            : ''}
                          {/* <p> Delivered on {order.delivery_date}</p> */}
                          {order.order_status == "Order Return" ?
                           <p className="text-error">
                           {order.order_status}
                         </p>:
                            <p className="text-success">
                              {order.order_status}
                            </p>
                          }
                        </div>

                      </Col>
                    </Row>
                  </div>
                  <div className="order-footer">
                    <Row>

                      <Col lg={6} md={6} xs={12}>
                        <div className="order-left">
                          <p>
                            Ordered On:
                            <strong> {order.order_date}</strong>

                          </p>
                        </div>
                      </Col>

                      <Col lg={6} md={6} xs={12}>
                        <div className="order-right text-end">
                          <p>
                            Grand Total: <strong>₹{order.products[0].total_order_price}</strong>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div></>
              ))) : <p className="empty-address">{message}</p> : <div className="loader-box"><div className="lds-ripple"><div className="loader-text"></div></div></div>}
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default MyOrders;
